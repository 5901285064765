.section {
  background: #fff;
}

.container {
  max-width: 64em;
}

.app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.main {
  flex: 1;
}

.social-block {
  display: inline-block;
  margin-right: 16px;
  vertical-align: middle;
}

.footer svg {
  margin: 0 4px;
}

.footer a:link,
.footer a:visited,
.footer a:focus,
.footer a:hover {
  color: inherit;
}

.loading-center {
  margin: 0 auto;
}

.page-selected {
  background-color: hsl(204, 86%, 53%);
}

.page-selected .media-content .title,
.page-selected .media-content .subtitle {
  color: white;
}

.card figure img {
  border: solid 1px #eee;
}

.page-card {
  max-height: 96px;
}

.page-card .media-content {
  overflow: hidden;
}

.page-card .media-content .title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.react-calendar {
  border-radius: 4px;
  max-width: 100%;
  font: inherit;
  border: 0;
  overflow: hidden;
  box-shadow: 0 20px 30px 0 rgba(16, 36, 94, 0.2);
}
