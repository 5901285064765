.has-margin-1 {
  margin: 3rem !important; }

.has-margin-b-1 {
  margin-bottom: 3rem !important; }

.has-margin-l-1 {
  margin-left: 3rem !important; }

.has-margin-r-1 {
  margin-right: 3rem !important; }

.has-margin-t-1 {
  margin-top: 3rem !important; }

.has-margin-bl-1 {
  margin-bottom: 3rem !important;
  margin-left: 3rem !important; }

.has-margin-br-1 {
  margin-bottom: 3rem !important;
  margin-right: 3rem !important; }

.has-margin-bt-1 {
  margin-bottom: 3rem !important;
  margin-top: 3rem !important; }

.has-margin-lr-1 {
  margin-left: 3rem !important;
  margin-right: 3rem !important; }

.has-margin-lt-1 {
  margin-left: 3rem !important;
  margin-top: 3rem !important; }

.has-margin-rt-1 {
  margin-right: 3rem !important;
  margin-top: 3rem !important; }

.has-margin-blr-1 {
  margin-bottom: 3rem !important;
  margin-left: 3rem !important;
  margin-right: 3rem !important; }

.has-margin-blt-1 {
  margin-bottom: 3rem !important;
  margin-left: 3rem !important;
  margin-top: 3rem !important; }

.has-margin-brt-1 {
  margin-bottom: 3rem !important;
  margin-right: 3rem !important;
  margin-top: 3rem !important; }

.has-margin-lrt-1 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
  margin-top: 3rem !important; }

.has-padding-1 {
  padding: 3rem !important; }

.has-padding-b-1 {
  padding-bottom: 3rem !important; }

.has-padding-l-1 {
  padding-left: 3rem !important; }

.has-padding-r-1 {
  padding-right: 3rem !important; }

.has-padding-t-1 {
  padding-top: 3rem !important; }

.has-padding-bl-1 {
  padding-bottom: 3rem !important;
  padding-left: 3rem !important; }

.has-padding-br-1 {
  padding-bottom: 3rem !important;
  padding-right: 3rem !important; }

.has-padding-bt-1 {
  padding-bottom: 3rem !important;
  padding-top: 3rem !important; }

.has-padding-lr-1 {
  padding-left: 3rem !important;
  padding-right: 3rem !important; }

.has-padding-lt-1 {
  padding-left: 3rem !important;
  padding-top: 3rem !important; }

.has-padding-rt-1 {
  padding-right: 3rem !important;
  padding-top: 3rem !important; }

.has-padding-blr-1 {
  padding-bottom: 3rem !important;
  padding-left: 3rem !important;
  padding-right: 3rem !important; }

.has-padding-blt-1 {
  padding-bottom: 3rem !important;
  padding-left: 3rem !important;
  padding-top: 3rem !important; }

.has-padding-brt-1 {
  padding-bottom: 3rem !important;
  padding-right: 3rem !important;
  padding-top: 3rem !important; }

.has-padding-lrt-1 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  padding-top: 3rem !important; }

.has-margin-2 {
  margin: 2.5rem !important; }

.has-margin-b-2 {
  margin-bottom: 2.5rem !important; }

.has-margin-l-2 {
  margin-left: 2.5rem !important; }

.has-margin-r-2 {
  margin-right: 2.5rem !important; }

.has-margin-t-2 {
  margin-top: 2.5rem !important; }

.has-margin-bl-2 {
  margin-bottom: 2.5rem !important;
  margin-left: 2.5rem !important; }

.has-margin-br-2 {
  margin-bottom: 2.5rem !important;
  margin-right: 2.5rem !important; }

.has-margin-bt-2 {
  margin-bottom: 2.5rem !important;
  margin-top: 2.5rem !important; }

.has-margin-lr-2 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important; }

.has-margin-lt-2 {
  margin-left: 2.5rem !important;
  margin-top: 2.5rem !important; }

.has-margin-rt-2 {
  margin-right: 2.5rem !important;
  margin-top: 2.5rem !important; }

.has-margin-blr-2 {
  margin-bottom: 2.5rem !important;
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important; }

.has-margin-blt-2 {
  margin-bottom: 2.5rem !important;
  margin-left: 2.5rem !important;
  margin-top: 2.5rem !important; }

.has-margin-brt-2 {
  margin-bottom: 2.5rem !important;
  margin-right: 2.5rem !important;
  margin-top: 2.5rem !important; }

.has-margin-lrt-2 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
  margin-top: 2.5rem !important; }

.has-padding-2 {
  padding: 2.5rem !important; }

.has-padding-b-2 {
  padding-bottom: 2.5rem !important; }

.has-padding-l-2 {
  padding-left: 2.5rem !important; }

.has-padding-r-2 {
  padding-right: 2.5rem !important; }

.has-padding-t-2 {
  padding-top: 2.5rem !important; }

.has-padding-bl-2 {
  padding-bottom: 2.5rem !important;
  padding-left: 2.5rem !important; }

.has-padding-br-2 {
  padding-bottom: 2.5rem !important;
  padding-right: 2.5rem !important; }

.has-padding-bt-2 {
  padding-bottom: 2.5rem !important;
  padding-top: 2.5rem !important; }

.has-padding-lr-2 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important; }

.has-padding-lt-2 {
  padding-left: 2.5rem !important;
  padding-top: 2.5rem !important; }

.has-padding-rt-2 {
  padding-right: 2.5rem !important;
  padding-top: 2.5rem !important; }

.has-padding-blr-2 {
  padding-bottom: 2.5rem !important;
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important; }

.has-padding-blt-2 {
  padding-bottom: 2.5rem !important;
  padding-left: 2.5rem !important;
  padding-top: 2.5rem !important; }

.has-padding-brt-2 {
  padding-bottom: 2.5rem !important;
  padding-right: 2.5rem !important;
  padding-top: 2.5rem !important; }

.has-padding-lrt-2 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
  padding-top: 2.5rem !important; }

.has-margin-3 {
  margin: 2rem !important; }

.has-margin-b-3 {
  margin-bottom: 2rem !important; }

.has-margin-l-3 {
  margin-left: 2rem !important; }

.has-margin-r-3 {
  margin-right: 2rem !important; }

.has-margin-t-3 {
  margin-top: 2rem !important; }

.has-margin-bl-3 {
  margin-bottom: 2rem !important;
  margin-left: 2rem !important; }

.has-margin-br-3 {
  margin-bottom: 2rem !important;
  margin-right: 2rem !important; }

.has-margin-bt-3 {
  margin-bottom: 2rem !important;
  margin-top: 2rem !important; }

.has-margin-lr-3 {
  margin-left: 2rem !important;
  margin-right: 2rem !important; }

.has-margin-lt-3 {
  margin-left: 2rem !important;
  margin-top: 2rem !important; }

.has-margin-rt-3 {
  margin-right: 2rem !important;
  margin-top: 2rem !important; }

.has-margin-blr-3 {
  margin-bottom: 2rem !important;
  margin-left: 2rem !important;
  margin-right: 2rem !important; }

.has-margin-blt-3 {
  margin-bottom: 2rem !important;
  margin-left: 2rem !important;
  margin-top: 2rem !important; }

.has-margin-brt-3 {
  margin-bottom: 2rem !important;
  margin-right: 2rem !important;
  margin-top: 2rem !important; }

.has-margin-lrt-3 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
  margin-top: 2rem !important; }

.has-padding-3 {
  padding: 2rem !important; }

.has-padding-b-3 {
  padding-bottom: 2rem !important; }

.has-padding-l-3 {
  padding-left: 2rem !important; }

.has-padding-r-3 {
  padding-right: 2rem !important; }

.has-padding-t-3 {
  padding-top: 2rem !important; }

.has-padding-bl-3 {
  padding-bottom: 2rem !important;
  padding-left: 2rem !important; }

.has-padding-br-3 {
  padding-bottom: 2rem !important;
  padding-right: 2rem !important; }

.has-padding-bt-3 {
  padding-bottom: 2rem !important;
  padding-top: 2rem !important; }

.has-padding-lr-3 {
  padding-left: 2rem !important;
  padding-right: 2rem !important; }

.has-padding-lt-3 {
  padding-left: 2rem !important;
  padding-top: 2rem !important; }

.has-padding-rt-3 {
  padding-right: 2rem !important;
  padding-top: 2rem !important; }

.has-padding-blr-3 {
  padding-bottom: 2rem !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important; }

.has-padding-blt-3 {
  padding-bottom: 2rem !important;
  padding-left: 2rem !important;
  padding-top: 2rem !important; }

.has-padding-brt-3 {
  padding-bottom: 2rem !important;
  padding-right: 2rem !important;
  padding-top: 2rem !important; }

.has-padding-lrt-3 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  padding-top: 2rem !important; }

.has-margin-4 {
  margin: 1.5rem !important; }

.has-margin-b-4 {
  margin-bottom: 1.5rem !important; }

.has-margin-l-4 {
  margin-left: 1.5rem !important; }

.has-margin-r-4 {
  margin-right: 1.5rem !important; }

.has-margin-t-4 {
  margin-top: 1.5rem !important; }

.has-margin-bl-4 {
  margin-bottom: 1.5rem !important;
  margin-left: 1.5rem !important; }

.has-margin-br-4 {
  margin-bottom: 1.5rem !important;
  margin-right: 1.5rem !important; }

.has-margin-bt-4 {
  margin-bottom: 1.5rem !important;
  margin-top: 1.5rem !important; }

.has-margin-lr-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important; }

.has-margin-lt-4 {
  margin-left: 1.5rem !important;
  margin-top: 1.5rem !important; }

.has-margin-rt-4 {
  margin-right: 1.5rem !important;
  margin-top: 1.5rem !important; }

.has-margin-blr-4 {
  margin-bottom: 1.5rem !important;
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important; }

.has-margin-blt-4 {
  margin-bottom: 1.5rem !important;
  margin-left: 1.5rem !important;
  margin-top: 1.5rem !important; }

.has-margin-brt-4 {
  margin-bottom: 1.5rem !important;
  margin-right: 1.5rem !important;
  margin-top: 1.5rem !important; }

.has-margin-lrt-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
  margin-top: 1.5rem !important; }

.has-padding-4 {
  padding: 1.5rem !important; }

.has-padding-b-4 {
  padding-bottom: 1.5rem !important; }

.has-padding-l-4 {
  padding-left: 1.5rem !important; }

.has-padding-r-4 {
  padding-right: 1.5rem !important; }

.has-padding-t-4 {
  padding-top: 1.5rem !important; }

.has-padding-bl-4 {
  padding-bottom: 1.5rem !important;
  padding-left: 1.5rem !important; }

.has-padding-br-4 {
  padding-bottom: 1.5rem !important;
  padding-right: 1.5rem !important; }

.has-padding-bt-4 {
  padding-bottom: 1.5rem !important;
  padding-top: 1.5rem !important; }

.has-padding-lr-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important; }

.has-padding-lt-4 {
  padding-left: 1.5rem !important;
  padding-top: 1.5rem !important; }

.has-padding-rt-4 {
  padding-right: 1.5rem !important;
  padding-top: 1.5rem !important; }

.has-padding-blr-4 {
  padding-bottom: 1.5rem !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important; }

.has-padding-blt-4 {
  padding-bottom: 1.5rem !important;
  padding-left: 1.5rem !important;
  padding-top: 1.5rem !important; }

.has-padding-brt-4 {
  padding-bottom: 1.5rem !important;
  padding-right: 1.5rem !important;
  padding-top: 1.5rem !important; }

.has-padding-lrt-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  padding-top: 1.5rem !important; }

.has-margin-5 {
  margin: 1.25rem !important; }

.has-margin-b-5 {
  margin-bottom: 1.25rem !important; }

.has-margin-l-5 {
  margin-left: 1.25rem !important; }

.has-margin-r-5 {
  margin-right: 1.25rem !important; }

.has-margin-t-5 {
  margin-top: 1.25rem !important; }

.has-margin-bl-5 {
  margin-bottom: 1.25rem !important;
  margin-left: 1.25rem !important; }

.has-margin-br-5 {
  margin-bottom: 1.25rem !important;
  margin-right: 1.25rem !important; }

.has-margin-bt-5 {
  margin-bottom: 1.25rem !important;
  margin-top: 1.25rem !important; }

.has-margin-lr-5 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important; }

.has-margin-lt-5 {
  margin-left: 1.25rem !important;
  margin-top: 1.25rem !important; }

.has-margin-rt-5 {
  margin-right: 1.25rem !important;
  margin-top: 1.25rem !important; }

.has-margin-blr-5 {
  margin-bottom: 1.25rem !important;
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important; }

.has-margin-blt-5 {
  margin-bottom: 1.25rem !important;
  margin-left: 1.25rem !important;
  margin-top: 1.25rem !important; }

.has-margin-brt-5 {
  margin-bottom: 1.25rem !important;
  margin-right: 1.25rem !important;
  margin-top: 1.25rem !important; }

.has-margin-lrt-5 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
  margin-top: 1.25rem !important; }

.has-padding-5 {
  padding: 1.25rem !important; }

.has-padding-b-5 {
  padding-bottom: 1.25rem !important; }

.has-padding-l-5 {
  padding-left: 1.25rem !important; }

.has-padding-r-5 {
  padding-right: 1.25rem !important; }

.has-padding-t-5 {
  padding-top: 1.25rem !important; }

.has-padding-bl-5 {
  padding-bottom: 1.25rem !important;
  padding-left: 1.25rem !important; }

.has-padding-br-5 {
  padding-bottom: 1.25rem !important;
  padding-right: 1.25rem !important; }

.has-padding-bt-5 {
  padding-bottom: 1.25rem !important;
  padding-top: 1.25rem !important; }

.has-padding-lr-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important; }

.has-padding-lt-5 {
  padding-left: 1.25rem !important;
  padding-top: 1.25rem !important; }

.has-padding-rt-5 {
  padding-right: 1.25rem !important;
  padding-top: 1.25rem !important; }

.has-padding-blr-5 {
  padding-bottom: 1.25rem !important;
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important; }

.has-padding-blt-5 {
  padding-bottom: 1.25rem !important;
  padding-left: 1.25rem !important;
  padding-top: 1.25rem !important; }

.has-padding-brt-5 {
  padding-bottom: 1.25rem !important;
  padding-right: 1.25rem !important;
  padding-top: 1.25rem !important; }

.has-padding-lrt-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
  padding-top: 1.25rem !important; }

.has-margin-6 {
  margin: 1rem !important; }

.has-margin-b-6 {
  margin-bottom: 1rem !important; }

.has-margin-l-6 {
  margin-left: 1rem !important; }

.has-margin-r-6 {
  margin-right: 1rem !important; }

.has-margin-t-6 {
  margin-top: 1rem !important; }

.has-margin-bl-6 {
  margin-bottom: 1rem !important;
  margin-left: 1rem !important; }

.has-margin-br-6 {
  margin-bottom: 1rem !important;
  margin-right: 1rem !important; }

.has-margin-bt-6 {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important; }

.has-margin-lr-6 {
  margin-left: 1rem !important;
  margin-right: 1rem !important; }

.has-margin-lt-6 {
  margin-left: 1rem !important;
  margin-top: 1rem !important; }

.has-margin-rt-6 {
  margin-right: 1rem !important;
  margin-top: 1rem !important; }

.has-margin-blr-6 {
  margin-bottom: 1rem !important;
  margin-left: 1rem !important;
  margin-right: 1rem !important; }

.has-margin-blt-6 {
  margin-bottom: 1rem !important;
  margin-left: 1rem !important;
  margin-top: 1rem !important; }

.has-margin-brt-6 {
  margin-bottom: 1rem !important;
  margin-right: 1rem !important;
  margin-top: 1rem !important; }

.has-margin-lrt-6 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
  margin-top: 1rem !important; }

.has-padding-6 {
  padding: 1rem !important; }

.has-padding-b-6 {
  padding-bottom: 1rem !important; }

.has-padding-l-6 {
  padding-left: 1rem !important; }

.has-padding-r-6 {
  padding-right: 1rem !important; }

.has-padding-t-6 {
  padding-top: 1rem !important; }

.has-padding-bl-6 {
  padding-bottom: 1rem !important;
  padding-left: 1rem !important; }

.has-padding-br-6 {
  padding-bottom: 1rem !important;
  padding-right: 1rem !important; }

.has-padding-bt-6 {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important; }

.has-padding-lr-6 {
  padding-left: 1rem !important;
  padding-right: 1rem !important; }

.has-padding-lt-6 {
  padding-left: 1rem !important;
  padding-top: 1rem !important; }

.has-padding-rt-6 {
  padding-right: 1rem !important;
  padding-top: 1rem !important; }

.has-padding-blr-6 {
  padding-bottom: 1rem !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important; }

.has-padding-blt-6 {
  padding-bottom: 1rem !important;
  padding-left: 1rem !important;
  padding-top: 1rem !important; }

.has-padding-brt-6 {
  padding-bottom: 1rem !important;
  padding-right: 1rem !important;
  padding-top: 1rem !important; }

.has-padding-lrt-6 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  padding-top: 1rem !important; }

.has-margin-7 {
  margin: 0.75rem !important; }

.has-margin-b-7 {
  margin-bottom: 0.75rem !important; }

.has-margin-l-7 {
  margin-left: 0.75rem !important; }

.has-margin-r-7 {
  margin-right: 0.75rem !important; }

.has-margin-t-7 {
  margin-top: 0.75rem !important; }

.has-margin-bl-7 {
  margin-bottom: 0.75rem !important;
  margin-left: 0.75rem !important; }

.has-margin-br-7 {
  margin-bottom: 0.75rem !important;
  margin-right: 0.75rem !important; }

.has-margin-bt-7 {
  margin-bottom: 0.75rem !important;
  margin-top: 0.75rem !important; }

.has-margin-lr-7 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important; }

.has-margin-lt-7 {
  margin-left: 0.75rem !important;
  margin-top: 0.75rem !important; }

.has-margin-rt-7 {
  margin-right: 0.75rem !important;
  margin-top: 0.75rem !important; }

.has-margin-blr-7 {
  margin-bottom: 0.75rem !important;
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important; }

.has-margin-blt-7 {
  margin-bottom: 0.75rem !important;
  margin-left: 0.75rem !important;
  margin-top: 0.75rem !important; }

.has-margin-brt-7 {
  margin-bottom: 0.75rem !important;
  margin-right: 0.75rem !important;
  margin-top: 0.75rem !important; }

.has-margin-lrt-7 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
  margin-top: 0.75rem !important; }

.has-padding-7 {
  padding: 0.75rem !important; }

.has-padding-b-7 {
  padding-bottom: 0.75rem !important; }

.has-padding-l-7 {
  padding-left: 0.75rem !important; }

.has-padding-r-7 {
  padding-right: 0.75rem !important; }

.has-padding-t-7 {
  padding-top: 0.75rem !important; }

.has-padding-bl-7 {
  padding-bottom: 0.75rem !important;
  padding-left: 0.75rem !important; }

.has-padding-br-7 {
  padding-bottom: 0.75rem !important;
  padding-right: 0.75rem !important; }

.has-padding-bt-7 {
  padding-bottom: 0.75rem !important;
  padding-top: 0.75rem !important; }

.has-padding-lr-7 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important; }

.has-padding-lt-7 {
  padding-left: 0.75rem !important;
  padding-top: 0.75rem !important; }

.has-padding-rt-7 {
  padding-right: 0.75rem !important;
  padding-top: 0.75rem !important; }

.has-padding-blr-7 {
  padding-bottom: 0.75rem !important;
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important; }

.has-padding-blt-7 {
  padding-bottom: 0.75rem !important;
  padding-left: 0.75rem !important;
  padding-top: 0.75rem !important; }

.has-padding-brt-7 {
  padding-bottom: 0.75rem !important;
  padding-right: 0.75rem !important;
  padding-top: 0.75rem !important; }

.has-padding-lrt-7 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
  padding-top: 0.75rem !important; }

.has-bg-primary {
  background-color: #00d1b2 !important; }

.has-bg-success {
  background-color: #23d160 !important; }

.has-bg-warning {
  background-color: #ffdd57 !important; }

.has-bg-danger {
  background-color: #ff3860 !important; }

.is-marginless-b {
  margin-bottom: 0 !important; }

.is-marginless-l {
  margin-left: 0 !important; }

.is-marginless-r {
  margin-right: 0 !important; }

.is-marginless-t {
  margin-top: 0 !important; }

.is-marginless-bl {
  margin-bottom: 0 !important;
  margin-left: 0 !important; }

.is-marginless-br {
  margin-bottom: 0 !important;
  margin-right: 0 !important; }

.is-marginless-bt {
  margin-bottom: 0 !important;
  margin-top: 0 !important; }

.is-marginless-lr {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.is-marginless-lt {
  margin-left: 0 !important;
  margin-top: 0 !important; }

.is-marginless-rt {
  margin-right: 0 !important;
  margin-top: 0 !important; }

.is-marginless-blr {
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important; }

.is-marginless-blt {
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  margin-top: 0 !important; }

.is-marginless-brt {
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  margin-top: 0 !important; }

.is-marginless-lrt {
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 0 !important; }

.is-paddingless-b {
  padding-bottom: 0 !important; }

.is-paddingless-l {
  padding-left: 0 !important; }

.is-paddingless-r {
  padding-right: 0 !important; }

.is-paddingless-t {
  padding-top: 0 !important; }

.is-paddingless-bl {
  padding-bottom: 0 !important;
  padding-left: 0 !important; }

.is-paddingless-br {
  padding-bottom: 0 !important;
  padding-right: 0 !important; }

.is-paddingless-bt {
  padding-bottom: 0 !important;
  padding-top: 0 !important; }

.is-paddingless-lr {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.is-paddingless-lt {
  padding-left: 0 !important;
  padding-top: 0 !important; }

.is-paddingless-rt {
  padding-right: 0 !important;
  padding-top: 0 !important; }

.is-paddingless-blr {
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important; }

.is-paddingless-blt {
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-top: 0 !important; }

.is-paddingless-brt {
  padding-bottom: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important; }

.is-paddingless-lrt {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important; }

.hide-overflow-x {
  overflow-x: hidden; }

.hide-overflow-y {
  overflow-y: hidden; }

.hide-overflow {
  overflow: hidden; }

.has-position-absolute {
  position: absolute; }

.has-position-relative {
  position: relative; }

.has-position-fixed {
  position: fixed; }

.has-flex-center-items {
  display: flex;
  align-items: center; }

.has-flex-start-items {
  display: flex;
  align-items: flex-start; }

.has-flex-end-items {
  display: flex;
  align-items: flex-end; }

.is-clickable {
  cursor: pointer; }

.is-zoom-in {
  cursor: zoom-in; }

.is-zoom-out {
  cursor: zoom-out; }

.is-cell {
  cursor: cell; }

.is-not-allowed {
  cursor: not-allowed; }

.is-waiting {
  cursor: wait; }

.can-move {
  cursor: move; }

.has-border-dashed {
  border-style: dashed; }

.has-border-dotted {
  border-style: dotted; }

.has-border-double {
  border-style: double; }

.has-border-hidden {
  border-style: hidden; }

.has-border-solid {
  border-style: solid; }

.is-circle {
  border-radius: 60px; }

.unbreakable-text {
  white-space: nowrap; }

.has-margin-x-auto {
  margin: 0 auto; }

.has-text-underlined {
  text-decoration: underline; }
